import { QueryClient } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import { GetServerSidePropsContext } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Dialog } from 'antd-mobile';
import nookies from 'nookies';
import logo from 'public/img/login-logo.svg';
import { useGetMeQuery } from 'codegen/generated/graphql';
import Layout from 'components/Layout/Layout';
import { API_BASE_URL, CALLBACK_URL, PID_URL } from 'utils/config';
import graphqlRequestClient from 'utils/graphqlRequestClient';
import { getToken } from 'utils/auth';
import { defaultCookieConfig } from 'utils/config';
import styles from 'styles/Login.module.css';

const cx = classNames.bind(styles);

function Login() {
  const router = useRouter();

  const handleLogout = () => {
    const callbackOrigin = window.location.origin;
    router.push(`${PID_URL}/logout?redirect_uri=${callbackOrigin}`);
  };

  if (router.query?.error === 'invalidEmail') {
    Dialog.alert({
      content:
        '구성원 메일 주소가 아니에요. 반드시 본인의 회사 메일로 가입해 로그인해주세요.',
      confirmText: '확인',
      onConfirm: handleLogout,
    });
  }

  if (router.query?.modalId) {
    nookies.set(
      null,
      'next',
      `/home?modalId=${router.query.modalId}`,
      defaultCookieConfig
    );
  }

  return (
    <div className={cx('container')}>
      <div className={cx('body')}>
        <div className={cx('logo-img-container')}>
          <Image
            src={logo}
            width={300}
            height={300}
            layout="responsive"
            alt="logo"
          />
        </div>
        <a
          className={cx('login-button')}
          href={`${API_BASE_URL}/api/connect/pid?callback=${CALLBACK_URL}`}
        >
          핑크퐁통합회원 로그인
        </a>
        <p className={cx('login-text')}>
          새로운 핑크퐁 카페는 회사 이메일로 가입한 핑크퐁통합회원으로 이용하실
          수 있어요.
        </p>
      </div>
    </div>
  );
}

Login.getLayout = function getLayout(page: React.ReactNode) {
  return <Layout>{page}</Layout>;
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(
    useGetMeQuery.getKey(),
    useGetMeQuery.fetcher(graphqlRequestClient, undefined, {
      authorization: `Bearer ${getToken(context)}`,
    })
  );
  const me = queryClient.getQueryData(useGetMeQuery.getKey());

  if (me) {
    return {
      redirect: {
        destination: '/home',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}

export default Login;
